export default [{
        target: 'body',
        "header": {
            "title": "<b>Welcome to HQ!</b><br>Powered by Quility"
        },
        content: 'Click “Next” to take a tour of all our new features!',
        params: {
            placement: 'none',
            modifiers: {
                computeStyle: {
                    enabled: true,
                    fn(data) {
                        data.styles = Object.assign({}, data.styles, {
                            left: '50%',
                            top: '250px',
                            transform: 'translate(-50%, -50%)'
                        });

                        return data;
                    }
                }
            }
        }
    },
    {
        "target": "#v-step-1",
        "header": {
            "title": "<strong>Single Sign-on</strong>"
        },
        "content": "Use Quility HQ Single Sign-On (SSO) to easily access all your apps with a single click.",
        "params": {
            "placement": "right-end"
        }
    },

    {
        "target": "#v-step-2",
        "header": {
            "title": "<strong>Search</strong>"
        },
        "content": "Can’t find what you’re looking for? Search will find support docs, resources, even agents. Take a test drive!",
        "params": {
            "placement": "left-start"
        }
    },
    {

        "target": "#v-step-3",
        "header": {
            "title": "<strong>Notices</strong>"
        },
        "content": "Important updates from your agency, Quility, and Support tickets can be accessed here. ",
        "params": {
            "placement": "left-start"
        }
    },

    {

        "target": "#v-step-3a",
        "header": {
            "title": "<strong>My Profile</strong>"
        },
        "content": "Your profile includes your general info, states you are licensed in, your document repository and more.",
        "params": {
            "placement": "left-start"
        }
    },
    {
        "target": "#v-step-4",
        "header": {
            "title": "<strong>Main Menu</strong>"
        },
        "content": "Hover over the menu to expand and discover all your options. ",
        "params": {
            "placement": "left-top",
            "highlight": false
        },
        "offset": -300
    },
    {
        "target": "#v-step-5",
        "header": {
            "title": "<strong>Stay Up To Date</strong>"
        },
        "content": "Get the latest on contests, sponsored events and news.",
        "params": {
            "placement": "left"
        }
    },

    {
        "target": "#v-step-9",
        "header": {
            "title": "<strong>Demo Tour</strong>"
        },
        "content": "Many pages have a unique demo, always available to consult here.",
        "offset": -300,
        "params": {
            "placement": "right-start"
        }
    }
]
