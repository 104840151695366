export default [{
        target: 'body',
        "header": {
            "title": "<b>Welcome to the Stats Page!</b>"
        },
        content: 'Your stats, updated weekly, at a glance.',
        params: {
            placement: 'none',
            modifiers: {
                computeStyle: {
                    enabled: true,
                    fn(data) {
                        data.styles = Object.assign({}, data.styles, {
                            left: '50%',
                            top: '250px',
                            transform: 'translate(-50%, -50%)'
                        });

                        return data;
                    }
                }
            }
        }
    },
    {
        "target": "#v-owner-prod-1",
        "header": {
            "title": "<b>Production Stats for your total agency</b."
        },
        "content": "These are your agency’s submitted, placed and close ratio stats. Submitted, placed and unique writers are updated weekly. Close ratios are updated around the end of the month.",
        "offset": -300,
        "params": {
            "placement": "bottom-end"
        },
        "forRole": "AgencyOwner"
    },
    {
        "target": "#v-owner-base-shop",
        "header": {
            "title": "<b>Base Shop</b>"
        },
        "content": "Here you can drill down into the production of your base shop.",
        "offset": -300,
        "params": {
            "placement": "bottom-end"
        },
        "forRole": "AgencyOwner"
    },
    {
        "target": "#v-owner-personal",
        "header": {
            "title": "<b>My Production</b>"
        },
        "content": "These stats show your personal production. ",
        "offset": -300,
        "params": {
            "placement": "bottom-end"
        },
        "forRole": "AgencyOwner"
    },
    {
        "target": "#v-prod-1",
        "header": {
            "title": "<b>Production Stats</b>"
        },
        "content": "These are your submitted, placed and close ratio stats. Submitted, placed and unique writers are updated weekly. Close ratios are updated around the end of the month. ",
        "offset": -300,
        "params": {
            "placement": "right-end"
        },
        "forRole": "SalesRep"
    },
    {
        "target": "#v-promotion-2",
        "header": {
            "title": "<b>Promotion Tracker</b>"
        },
        "content": "The bars show submitted over placed apps for the past four months. The green lines indicate goals to reach your next promotional level.",
        "offset": -300,
        "params": {
            "placement": "left-start"
        },
        "forRole": "SalesRep"
    },
    {
        "target": "#v-monthly-3",
        "header": {
            "title": "<b>Monthly view</b>"
        },
        "content": "All submitted and placed apps over the last 9 months",
        "params": {
            "placement": "top-start"
        },
        "offset": -300,
        "forRole": "SalesRep"
    },

    {
        "target": "#v-exec-monthly",
        "header": {
            "title": "<b>Monthly view</b>"
        },
        "content": "All submitted and placed apps over the last 9 months",
        "params": {
            "placement": "top-start"
        },
        "offset": -300,
        "forRole": ["Staff", "Exec", "SuperAdmin"]
    },

    {
        "target": "#v-exec-prod",
        "header": {
            "title": "<b>Production Stats</b>"
        },
        "content": "These are your submitted, placed and close ratio stats. Submitted, placed and unique writers are updated weekly. Close ratios are updated around the end of the month. ",
        "offset": -300,
        "params": {
            "placement": "right-end"
        },
        "forRole": ["Staff", "Exec", "SuperAdmin"]
    },
    {
        "target": "#v-exec-carrier",
        "header": {
            "title": "<b>Sales by Carrier</b>"
        },
        "content": "Last months sales broken down by carrier. Hover over the carrier to see the actual dollar value.",
        "offset": -300,
        "params": {
            "placement": "right-end"
        },
        "forRole": ["Staff", "Exec", "SuperAdmin"]
    }
]
