export default [{
        target: 'body',
        "header": {
            "title": "<b>Leads</b>"
        },
        content: 'See your leads, get details, set the lead dispositon and more. ',
        params: {
            placement: 'none',
            modifiers: {
                computeStyle: {
                    enabled: true,
                    fn(data) {
                        data.styles = Object.assign({}, data.styles, {
                            left: '50%',
                            top: '250px',
                            transform: 'translate(-50%, -50%)'
                        });

                        return data;
                    }
                }
            }
        }
    },

    {
        "target": "#q-leads-step-1",
        "header": {
            "title": "<b>Lead Level</b>"
        },
        "content": "Lead level by percent of total leads",
        "params": {
            "placement": "bottom-start"
        },
        "offset": -300
    },
    {
        "target": "#q-leads-step-2",
        "header": {
            "title": "<b>Lead Status</b>"
        },
        "content": "Lead status by percent of total leads",
        "params": {
            "placement": "bottom-start"
        },
        "offset": -300
    },
    {
        "target": "#q-leads-step-3",
        "header": {
            "title": "<b>Lead Type</b>"
        },
        "content": "Lead type by percent of total leads",
        "params": {
            "placement": "bottom-start"
        },
        "offset": -300
    },
    {
        "target": "#q-leads-step-4",
        "header": {
            "title": "<b>My Leads</b>"
        },
        "content": "Search for a specific lead, filter by account type, status, lead level and more. To view more details on a lead, set disposition or add notes, click its row. You can also export your leads as a csv by clicking 'export resuits'.",
        "params": {
            "placement": "bottom-start"
        },
        "offset": -300
    }
]
