export default [{
        target: 'body',
        "header": {
            "title": "<b>Welcome to the Leaderboards!</b>"
        },
        content: 'View and export your automated leaderboards here.',
        params: {
            placement: 'none',
            modifiers: {
                computeStyle: {
                    enabled: true,
                    fn(data) {
                        data.styles = Object.assign({}, data.styles, {
                            left: '50%',
                            top: '250px',
                            transform: 'translate(-50%, -50%)'
                        });

                        return data;
                    }
                }
            }
        }
    },

    {
        "target": "#q-leaderboard-step-1",
        "header": {
            "title": "<b>Leaderboards</b>"
        },
        "content": "Toggle between weekly and monthly views.",
        "params": {
            "placement": "left-start"
        },
        "offset": -300
    },
    {
        "target": "#q-leaderboard-step-2",
        "header": {
            "title": "<b>Export</b>"
        },
        "content": "Export your leaderboard data as a CSV, suitable for import into a spreadsheet.",
        "params": {
            "placement": "bottom-start"
        },
        "offset": -300
    },
    {
        "target": "#q-leaderboard-step-3",
        "header": {
            "title": "<b>Search</b>"
        },
        "content": "Search for any agent to view their current ranking. ",
        "params": {
            "placement": "bottom-start"
        },
        "offset": -300
    }
]
