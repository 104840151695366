export default [{
        target: 'body',
        "header": {
            "title": "<b>Welcome to the Agency Page!</b>"
        },
        content: 'View all the tools available to set your agency up for success.',
        params: {
            placement: 'none',
            modifiers: {
                computeStyle: {
                    enabled: true,
                    fn(data) {
                        data.styles = Object.assign({}, data.styles, {
                            left: '50%',
                            top: '250px',
                            transform: 'translate(-50%, -50%)'
                        });

                        return data;
                    }
                }
            }
        }
    },

    {
        "target": "#v-owner-agent-directory",
        "header": {
            "title": "<b>Agent Directory</b>"
        },
        "content": "Find contact info, leadership levels and status of all your agents. Drill deeper to access their production stats, licensing info, documents and more.",
        "params": {
            "placement": "bottom-start"
        },
        "forRole": ["AgencyOwner", "Staff", "Exec", "SuperAdmin"]
    },
    {
        "target": "#v-new-agent",
        "header": {
            "title": "<b>New Agent</b>"
        },
        "content": "Add new agents to the system here.",
        "params": {
            "placement": "bottom-start"
        },
        "forRole": ["Staff", "SuperAdmin"]
    },
    {
        "target": "#v-owner-recruiting",
        "header": {
            "title": "<b>Recruiting Stats</b>"
        },
        "content": "Find new agent KPIs here.",
        "params": {
            "placement": "bottom-start"
        },
        "forRole": ["AgencyOwner", "Staff", "Exec", "SuperAdmin"]
    },
    {
        "target": "#v-owner-structure",
        "header": {
            "title": "<b>Agency Structure</b>"
        },
        "content": "An interactive representation of your entire agency.",
        "params": {
            "placement": "bottom-start"
        },
        "forRole": ["AgencyOwner", "Staff", "Exec", "SuperAdmin"]
    },
    {
        "target": "#v-bc",
        "header": {
            "title": "<b>Builders Corner.</b>"
        },
        "content": "Find corporate overview and recruiting assets to build your agency here. Direct access to our ZipRecruiter portal.",
        "params": {
            "placement": "bottom-start"
        }
    }
]
