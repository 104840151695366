<template>
    <div>
        <!--Add a q-tour element for each tour within hq //-->
        <q-tour name="DemoAgency" :steps="steps.agency_steps"></q-tour>
        <q-tour name="DemoDashboard" :steps="steps.dashboard_steps"></q-tour>
        <q-tour name="DemoCorpDashboard" :steps="steps.dashboard_corp_steps"></q-tour>
        <q-tour name="DemoLeaderboards" :steps="steps.my_leaderboards_steps"></q-tour>
        <q-tour name="DemoMyStats" :steps="steps.my_stats_steps"></q-tour>
        <q-tour name="DemoSupport" :steps="steps.support_sales_rep_steps"></q-tour>
        <q-tour name="DemoPendingPolicy" :steps="steps.my_pending_policy_steps"></q-tour>
        <q-tour name="DemoLeads" :steps="steps.my_leads_steps"></q-tour>
    </div>
</template>
<script>
//Uses a customized tour component to allow role based steps and other options. 
import QTour from '@/components/demo/QTour.vue'
//the js files for the steps... switched from json to js
import agency_steps from '@/components/demo/agency_steps.js'
import dashboard_steps from '@/components/demo/dashboard_steps.js'
import dashboard_corp_steps from '@/components/demo/dashboard_corp_steps.js'
import my_leaderboards_steps from '@/components/demo/my_leaderboards_steps.js'
import my_stats_steps from '@/components/demo/my_stats_steps.js'
import support_sales_rep_steps from '@/components/demo/support_sales_rep_steps.js'
import my_pending_policy_steps from '@/components/demo/my_pending_policy_steps.js'
import my_leads_steps from '@/components/demo/my_leads_steps.js'


export default {
    name: 'QTourWrapper',
    data() {
        return {
            //Add your imported steps to this object. 
            steps: {
                "agency_steps": agency_steps,
                "dashboard_steps": dashboard_steps,
                "dashboard_corp_steps": dashboard_corp_steps,
                "my_leaderboards_steps": my_leaderboards_steps,
                "my_stats_steps": my_stats_steps,
                "support_sales_rep_steps": support_sales_rep_steps,
                "my_pending_policy_steps": my_pending_policy_steps,
                "my_leads_steps": my_leads_steps

            }
        }
    },
    mounted: function() {

    },
    methods: {},
    components: {
        QTour
    }
}

</script>
