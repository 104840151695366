export default [{
        target: 'body',
        "header": {
            "title": "<b>Welcome to Support!</b>"
        },
        content: 'Toggle between finding answers to frequently asked questions and your open support tickets.',
        params: {
            placement: 'none',
            modifiers: {
                computeStyle: {
                    enabled: true,
                    fn(data) {
                        data.styles = Object.assign({}, data.styles, {
                            left: '50%',
                            top: '250px',
                            transform: 'translate(-50%, -50%)'
                        });

                        return data;
                    }
                }
            }
        }
    },
    {
        "target": "#v-support-1",
        "header": {
            "title": "<b>Support</b>"
        },
        "content": "Toggle between finding answers to common question and your open support tickets.",
        "params": {
            "placement": "bottom-start"
        },
        "offset": -300
    },
    {
        "target": "#v-support-2",
        "header": {
            "title": "<b>HELP!</b>"
        },
        "content": "Choose the category you need help with.",
        "params": {
            "placement": "bottom-start"
        }
    },
    {
        "target": "#v-support-3",
        "header": {
            "title": "<b>Common solutions</b>"
        },
        "content": "Search for answers to frequently asked questions.",
        "params": {
            "placement": "bottom-start"
        }
    },
    {
        "target": "#v-support-4",
        "header": {
            "title": "<b>Open a support ticket</b>"
        },
        "content": "Can’t find an answer to your question in the FAQs? Open a support ticket. ",
        "params": {
            "placement": "bottom-start"
        }
    },

    {
        "target": "#v-support-5",
        "header": {
            "title": "<b>Support Tickets</b>"
        },
        "content": "Check the status of your support tickets.",
        "params": {
            "placement": "bottom-start"
        }
    }
]
