export default [{
        target: 'body',
        "header": {
            "title": "<b>Your Pending Policies</b>"
        },
        content: 'Dig into your submitted apps to see which need more info before they can be processed. Keep in mind that not all your pending policies will be here since not all carriers supply this data.',
        params: {
            placement: 'none',
            modifiers: {
                computeStyle: {
                    enabled: true,
                    fn(data) {
                        data.styles = Object.assign({}, data.styles, {
                            left: '50%',
                            top: '250px',
                            transform: 'translate(-50%, -50%)'
                        });

                        return data;
                    }
                }
            }
        }
    },

    {
        "target": "#q-pending-policy-step-1",
        "header": {
            "title": "<b>My Pending Policies</b>"
        },
        "content": "Basic info for your pending policiesis is shown in the table view. Click the eye icon for more indepth detail or the Carrier Login button to log into the carrier website. Not all carriers supply pending policy data so remember to check the bottom table for links to carriers where you have a writing number but and no data is available.",
        "params": {
            "placement": "right-start"
        },
        "offset": -300
    },
    {
        "target": "#q-pending-policy-step-2",
        "header": {
            "title": "<b>Base Shop Pending Policies</b>"
        },
        "content": "This view allows agency owners to see all pending policies for their base shop. It works just like the My Pending Policies section. Keep in mind that not all carriers offer data. Use the links in the bottom table to login and check with those that don't.",
        "params": {
            "placement": "left-start"
        },
        "offset": -300
    }
]
