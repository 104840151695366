<template>
    <div>
        <v-tour :name="name" :steps="role_steps" :options="default_options"></v-tour>
        <v-overlay :value="show_overlay" z-index="0"></v-overlay>
    </div>
</template>
<script>
export default {
    props: ["name", "steps", "options"],
    data() {
        return {
            //if you want to show the overlay... doesn't work with the nav elements though. 
            demoCallbacks: {
                onStart: this.showOverlay,
                onFinish: this.hideOverlay
            },
            show_overlay: false
        }
    },
    computed: {
        default_options: function() {
            if (typeof this.options == "undefined") {
                return {
                    highlight: true
                }
            }
            return this.options
        },
        role_steps: function() {
            var s = []
            for (var i = 0; i < this.steps.length; i++) {
                if (typeof this.steps[i].forRole == "undefined" || this.steps[i].forRole == "All") {
                    s.push(this.steps[i])
                } else {
                    if (typeof this.steps[i].forRole == "string" && this.steps[i].forRole == this.role) {
                        s.push(this.steps[i])
                    } else if (this.steps[i].forRole.indexOf(this.role) > -1) {
                        s.push(this.steps[i])
                    }
                }
            }
            return s;
        },
    },
    methods: {
        showOverlay: function(currentStep) {
            this.show_overlay = true;
        },
        hideOverlay: function(currentStep) {
            this.show_overlay = false;
        }
    },
}

</script>
<style>
</style>
